export default function CategoryText({ text, title, subTitle }) {
  return (
    <>
      <div className="justify-center flex">
        <div className="px-6 py-2 bg-charcoal rounded-3xl text-center justify-center items-center">
          <p className="text-white text-base font-semibold uppercase">{text}</p>
        </div>
      </div>
      <div className="justify-center flex text-center">
        <h1 className="text-[36px] md:text-[56px] font-bold tracking-tighter leading-none pt-4 pb-2">
          {title}
        </h1>
      </div>
      <div className="justify-center flex">
        <h2 className="text-[20px] md:text-[34px] tracking-tighter leading-none text-center pt-2 pb-4">
          {subTitle}
        </h2>
      </div>
    </>
  );
}
