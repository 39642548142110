import ContactUs from "../shared/common/ContactUs";
import Footer from "../shared/common/Footer";
import Menu from "../shared/common/Menu";

export default function EarlyBirdSignUp() {
  return (
    <div>
      <Menu />
      <ContactUs isEarlyBirdSignUp={true} />
      <Footer />
    </div>
  );
}
