import Menu from "../shared/common/Menu";
import { Link } from "react-router-dom";
import Footer from "../shared/common/Footer";

export default function TermsAndConditions() {
  return (
    <>
      <Menu />
      <div className="md:container mb-6">
        <h1 className="text-charcoal text-2xl font-bold mx-8 md:mx-16 my-4">
          Usage of Logissy
        </h1>
        <ul className="list-disc text-charcoal mx-8 md:mx-24">
          <li className="my-2">
            Any person ("User") accessing Dream11 or the Dream11 App ('Dream11
            platform') for participating in the various contests and games
            (including fantasy games), available on Dream11 platform
            ("Contest(s)") ('Dream11 Services') shall be bound by these Terms
            and Conditions, and all other rules, regulations and terms of use
            referred to herein or provided by Dream11 in relation to any Dream11
            Services
          </li>
          <li className="my-2">
            Dream11 shall be entitled to modify these Terms and Conditions,
            rules, regulations and terms of use referred to herein or provided
            by Dream11 in relation to any Dream11 Services, at any time, by
            posting the same on Dream11. Use of Dream11 constitutes the User's
            acceptance of such Terms and Conditions, rules, regulations and
            terms of use referred to herein or provided by Dream11 in relation
            to any Dream11 Services, as may be amended from time to time.
            Dream11 may, at its sole discretion, also notify the User of any
            change or modification in these Terms and Conditions, rules,
            regulations and terms of use referred to herein or provided by
            Dream11, by way of sending an email to the User's registered email
            address or posting notifications in the User accounts. The User may
            then exercise the options provided in such an email or notification
            to indicate non-acceptance of the modified Terms and Conditions,
            rules, regulations and terms of use referred to herein or provided
            by Dream11. If such options are not exercised by the User within the
            time frame prescribed in the email or notification, the User will be
            deemed to have accepted the modified Terms and Conditions, rules,
            regulations and terms of use referred to herein or provided by
            Dream11
          </li>
          <li className="my-2">
            Certain Dream11 Services being provided on Dream11 may be subject to
            additional rules and regulations set down in that respect. To the
            extent that these Terms and Conditions are inconsistent with the
            additional conditions set down, the additional conditions shall
            prevail
          </li>
          <li className="my-2">
            Dream11 may, at its sole and absolute discretion:
          </li>
          <li className="my-2">
            Restrict, suspend, or terminate any User's access to all or any part
            of Dream11 or Dream11 Platform Services;
          </li>
          <li className="my-2">
            Change, suspend, or discontinue all or any part of the Dream11
            Platform Services;
          </li>
          <li className="my-2">
            Reject, move, or remove any material that may be submitted by a
            User;
          </li>
          <li className="my-2">
            Move or remove any content that is available on Dream11 Platform;
          </li>
          <li className="my-2">
            Establish general practices and limits concerning use of Dream11
            Platform;
          </li>
          <li className="my-2">
            Offer discounts to its users in form it deems fit ("Discount
            Bonus"). All such discounts shall be credited in a separate account
            called as Discount Bonus Account
          </li>
          <li className="my-2">
            Revise or make additions and/or deletions to the roster of players
            available for selection in a Contest on account of revisions to the
            roster of players involved in the relevant Sports Event;
          </li>
          <li className="my-2">
            Assign its rights and liabilities to all User accounts hereunder to
            any entity (post such assignment intimation of such assignment shall
            be sent to all Users to their registered email ids)
          </li>
          <li className="my-2">
            In the event any User breaches, or Dream11 reasonably believes that
            such User has breached these Terms and Conditions, or has illegally
            or improperly used Dream11 or the Dream11 Services, Dream11 may, at
            its sole and absolute discretion, and without any notice to the
            User, restrict, suspend or terminate such User's access to all or
            any part of Dream11 Contests or the Dream11 Platform, deactivate or
            delete the User's account and all related information on the
            account, delete any content posted by the User on Dream11 and
            further, take technical and legal steps as it deems necessary
          </li>
        </ul>
        <p className="mx-8 md:mx-20 mt-10 mb-4">
          All information collected from Users, such as registration and credit
          card information, is subject to Dream11's Privacy Policy which is
          available at{" "}
          <Link
            className="text-blue underline"
            to="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </p>
      </div>
      <Footer />
    </>
  );
}
