import CookieConsentMessage from "../shared/ui-kit/CookieConsentMessage";
import Banner from "../shared/common/Banner";
import Menu from "../shared/common/Menu";
import Faq from "../shared/common/Faq";
import CharacteristicsSection from "../shared/sections/CharacteristicsSection";
import ContactUs from "../shared/common/ContactUs";
import Footer from "../shared/common/Footer";
import GlobalSupplyChainSection from "../shared/sections/GlobalSupplyChainSection";
// TODO: Uncomment when customer stories is available
// import CustomerStoriesSection from "../shared/sections/CustomerStoriesSection";
import TraitsSection from "../shared/sections/TraitsSection";
import LandingPageInfoSection from "../shared/sections/LandingPageInfoSection";
import { useState } from "react";
import KeyNumbersSection from "../shared/sections/KeyNumbersSection";
import ValuePropositionSection from "../shared/sections/ValuePropositionSection";

export default function HomePage({ lightTheme }) {
  const [isBannerOpen, setIsBannerOpen] = useState(true);

  const closeBanner = () => {
    setIsBannerOpen(false);
  };

  return (
    <div className="justify-center bg-lightCyanBlue">
      <CookieConsentMessage />
      <Banner isBannerOpen={isBannerOpen} closeBanner={closeBanner} />
      <Menu page="/" isBannerOpen={isBannerOpen} lightTheme={lightTheme} />
      <LandingPageInfoSection
        isBannerOpen={isBannerOpen}
        lightTheme={lightTheme}
      />
      <TraitsSection />
      <GlobalSupplyChainSection />
      <CharacteristicsSection />
      {/* <CustomerStoriesSection /> */}
      <ValuePropositionSection />
      <KeyNumbersSection />
      <Faq />
      <ContactUs id="contactUs" />
      <Footer />
    </div>
  );
}
