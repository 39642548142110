import { useState, useEffect } from "react";
import PlayIcon from "../../../assets/icons/play-icon.svg";
import YoutubeModal from "../ui-kit/YoutubeModal";
import Button from "../ui-kit/Button";
import { Link } from "react-router-dom";

export default function CareersPageInfoSection() {
  const [showModal, setShowModal] = useState(false);
  const [isSmallBtn, setIsSmallBtn] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallBtn(window.innerWidth >= 350 && window.innerWidth <= 380);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleClick = () => {
    const targetPosition =
      window.innerWidth <= 640
        ? window.scrollY + 36 * 16
        : window.scrollY + 24 * 16;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showModal && <YoutubeModal onClose={toggleModal} />}
      <div className="absolute top-24 md:top-28 lg:top-20 left-1/2 transform -translate-x-1/2 w-full lg:w-1/3 text-center px-8 lg:px-0">
        <h1 className="text-[36px] md:text-[56px] text-white font-bold tracking-tighter mb-8 lg:mb-6">
          Join our Team
        </h1>
        <h2 className="text-xl text-offWhite mb-8">
          Logissy empowers shippers, freight forwarders & carriers to automate
          the freight & payment process
        </h2>
        <div className="flex flex-col md:flex-row justify-center gap-8 w-3/5 md:w-auto mx-auto md:mx-0">
          <Button
            text="How it works"
            type="transparent"
            icon={PlayIcon}
            handleClick={() => {
              toggleModal();
            }}
            hoverEffect={true}
            smallBtn={isSmallBtn}
          />
          <Link to="/careers" className="hidden" onClick={handleClick}>
            <Button text="View Job Openings" hoverEffect={true} />
          </Link>
          <Link to="/careers" onClick={handleClick} className="md:hidden">
            <Button
              text="&nbsp;&nbsp;&nbsp;&nbsp;Job Openings&nbsp;&nbsp;&nbsp;&nbsp;"
              hoverEffect={true}
              smallBtn={isSmallBtn}
            />
          </Link>
          <Link to="/careers" onClick={handleClick} className="hidden md:block">
            <Button
              text="Job Openings"
              hoverEffect={true}
              smallBtn={isSmallBtn}
            />
          </Link>
        </div>
      </div>
    </>
  );
}
