import { useEffect } from "react";
import { Link } from "react-router-dom";
import LogissyLogoIcon from "../../../assets/icons/logissy-logo-icon.svg";
import LinkedInLogo from "../../../assets/icons/linkedin-icon.svg";
import NewsLetter from "./Newsletter";

export default function Footer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    const targetPosition =
      window.innerWidth <= 640
        ? window.scrollY - 42 * 16
        : window.scrollY - 24 * 16;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-white p-6">
      <div className="md:hidden">
        <NewsLetter />
      </div>
      <div className="px-2 py-3 md:container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="hidden md:flex flex-col items-center md:items-start text-textGrey my-4 md:my-0">
          <Link to="/">
            <img src={LogissyLogoIcon} alt="Logissy Logo " className="mb-4" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/logissy/"
            target="_blank"
            className="-ml-1"
          >
            <img src={LinkedInLogo} alt="linkedin logo" />
          </Link>
          {/* TODO: Update correct address when the address is available */}
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>

        <div className="flex space-x-8 my-4 md:my-0">
          <div>
            <h3 className="font-medium uppercase text-sm text-textDarkGrey mt-2 md:mt-0 mb-4">
              More about Logissy
            </h3>
            <ul className="text-center md:text-left">
              <li className="my-1">
                <Link
                  to="/about"
                  className="text-textGrey font-bold text-lg hover:underline underline-offset-8"
                >
                  About
                </Link>
              </li>
              <li className="my-1">
                <Link to="/careers" className="text-textGrey font-bold text-lg">
                  <span className="hover:underline underline-offset-8">
                    Careers
                  </span>
                  <span className="uppercase bg-green text-[10px] p-1 rounded text-white ml-2">
                    New
                  </span>
                </Link>
              </li>
              <li className="my-1">
                <Link
                  className="text-textGrey font-bold text-lg hover:underline underline-offset-8"
                  onClick={handleClick}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="md:hidden flex flex-col items-center md:items-start text-textGrey my-4 md:my-0">
          <Link to="/">
            <img src={LogissyLogoIcon} alt="Logissy Logo " className="mb-4" />
          </Link>
          <Link to="https://www.linkedin.com/company/logissy/" target="_blank">
            <img src={LinkedInLogo} alt="linkedin logo" />
          </Link>
          {/* TODO: Uncomment when the address is available */}
          {/* <p>701 Minoo Minar, V.d.rd, Andheri</p>
          <p>(West), Mumbai,</p>
          <p>Maharashtra, IN</p>
          <p>Zip Code 400053</p> */}
        </div>

        <div className="my-4 md:my-0 hidden md:block">
          <NewsLetter />
        </div>
      </div>

      <div className="border-t-2 border-greyBlue mt-2 mb-6 md:my-8"></div>
      <div className="hidden md:flex justify-between text-textGrey mb-4">
        <p>©2024 Logissy. All Rights Reserved</p>
        <div className="flex gap-4">
          <Link
            to="/privacy-policy"
            className="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"
          >
            Privacy Policy
          </Link>
          {/* TODO: Uncomment when TnC is available */}
          {/* <Link
            to="/terms-and-conditions"
            target="_blank"
            className="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"
          >
            Terms & Conditions
          </Link> */}
        </div>
      </div>
      <div className="md:hidden flex justify-center text-textGrey">
        <Link
          to="/privacy-policy"
          className="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"
        >
          Privacy Policy
        </Link>
        {/* TODO: Uncomment when TnC is available */}
        {/* <Link
          to="/terms-and-conditions"
          target="_blank"
          className="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"
        >
          Terms & Conditions
        </Link> */}
      </div>
      <p className="text-textGrey md:hidden text-center mt-6">
        ©2024 Logissy. All Rights Reserved
      </p>
    </footer>
  );
}
