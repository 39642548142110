import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadCloud from "../../../assets/icons/upload-cloud.svg";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import FileIcon from "../../../assets/icons/file-icon.svg";

export default function FileUpload({ fileUrl, setFileUrl }) {
  const [file, setFile] = useState(null);

  const onFileChange = async (event) => {
    if (
      event.target.files[0].type === "application/pdf" &&
      event.target.files[0].size <= 5242880
    ) {
      setFile(event.target.files[0]);
      try {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/uploadFiles`;
        const response = await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setFileUrl(response.data.data.fileUrl);
      } catch (error) {
        console.error("Error during file upload:", error);
        toast.error("An error occurred during file upload. Please try again.");
      }
    } else {
      toast.error("Please upload a PDF file of size less than 5 MB.");
    }
  };

  const clearFile = () => {
    setFile(null);
    setFileUrl("");
  };

  return (
    <div className="flex justify-center items-center bg-gray-100">
      {!file && (
        <div className="bg-silverBlue p-8 rounded-2xl text-center">
          <div className="mb-4">
            <img
              src={UploadCloud}
              alt="upload cloud icon"
              className="mx-auto"
            />
            <h2 className="text-2xl font-bold text-gunMetal">Drag & Drop</h2>
            <p className="text-textDarkGrey my-2">
              File Type <span className="font-semibold">.pdf only</span>
            </p>
            <p className="text-textDarkGrey font-semibold my-4"> - OR - </p>
          </div>
          <label
            htmlFor="file-upload"
            className="cursor-pointer bg-gunMetal text-white font-semibold py-2 px-4 inline-block rounded-3xl"
          >
            Choose File
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={onFileChange}
            accept=".pdf"
            className="hidden"
          />
          <div className="flex text-xs mx-8 gap-2 justify-center my-4">
            <p className="text-textDarkGrey">
              File Type <span className="font-semibold">.pdf only</span>
            </p>
            <p> &bull;</p>
            <p className="text-textDarkGrey">
              max. file size <span className="font-semibold">5 MB</span>
            </p>
          </div>
        </div>
      )}

      {file && (
        <div>
          <div className="flex gap-4">
            <div className="bg-silverBlue p-3 rounded-md flex w-64 md:w-80">
              <img src={FileIcon} alt="file icon" />
              <span className="text-blue truncate font-semibold ml-2">
                {file.name}
              </span>
            </div>
            <div className="bg-errorRed py-2 px-3 rounded-md cursor-pointer flex">
              <img src={DeleteIcon} alt="delete icon" onClick={clearFile} />
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
