import { useState } from "react";
import Button from "../ui-kit/Button";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function NewsLetter() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const subscribeNewsLetter = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/subscribeNewsLetter`, {
        emailId: email,
      })
      .then((res) => {
        if (res.data.meta.status)
          toast.success("Thank you for subscribing to our newsletter!");
        else toast.success("Your email is already subscribed!");
      })
      .catch(() => {
        toast.error("Something went wrong.");
      });
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidEmail && email) {
      subscribeNewsLetter();
    } else {
      setIsValidEmail(false);
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div>
      <h3 className="font-bold inline text-2xl">Sign Up </h3>
      <h3 className="inline text-2xl font-medium">for our Newsletter</h3>
      <p className="text-textGrey italic mb-6">
        Stay tuned to important news & updates from Logissy
      </p>
      <form className="relative" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          className={`border ${
            isValidEmail ? "border-textGrey" : "border-errorRed"
          } py-2 md:py-3 px-6 rounded-full w-full`}
          value={email}
          onChange={handleInputChange}
        />
        <div className="absolute top-0 mt-[0.5px] md:mt-[1px] right-0">
          <Button text="Submit" hoverEffect={false} />
        </div>
      </form>
      {!isValidEmail && (
        <p className="text-errorRed mt-2 text-sm font-medium italic text-left">
          <img src={WarningIcon} alt="warning icon" className="inline mr-1" />
          Enter a valid email address
        </p>
      )}
      <ToastContainer autoClose={10000} />
    </div>
  );
}
