import ContactUs from "../shared/common/ContactUs";
import Footer from "../shared/common/Footer";
import Menu from "../shared/common/Menu";
// import AboutUsCard from "../shared/ui-kit/AboutUsCard";
// import CategoryText from "../shared/ui-kit/CategoryText";
import JoinOurTeamCard from "../shared/ui-kit/JoinOurTeamCard";

export default function AboutUsPage() {
  const data = [
    {
      name: "Name #01",
      role: "CEO",
      imageSrc: "../../../assets/images/avatar.png",
    },
    {
      name: "Name #02",
      role: "CTO",
      imageSrc: "../../../assets/images/avatar.png",
    },
    {
      name: "Name #03",
      role: "COO",
      imageSrc: "../../../assets/images/avatar.png",
    },
    {
      name: "Name #04",
      role: "CFO",
      imageSrc: "../../../assets/images/avatar.png",
    },
    {
      name: "Name #05",
      role: "CMO",
      imageSrc: "../../../assets/images/avatar.png",
    },
    {
      name: "Name #06",
      role: "CIO",
      imageSrc: "../../../assets/images/avatar.png",
    },
    {
      name: "Name #07",
      role: "CDO",
      imageSrc: "../../../assets/images/avatar.png",
    },
    {
      name: "Name #08",
      role: "CPO",
      imageSrc: "../../../assets/images/avatar.png",
    },
  ];

  return (
    <div>
      <Menu page="about" />
      <div className="max-h-4 md:max-h-screen my-8 mx-8 md:mx-16 text-center md:text-left absolute top-20 md:top-auto bottom-0">
        <h1 className="text-[36px] md:text-[56px] text-white font-bold tracking-tighter mb-8">
          About Us
        </h1>
        <h3 className="text-white text-xl md:w-2/3 lg:w-1/3 mb-8">
          Logissy empowers shippers, freight forwarders & carriers to automate
          the freight & payment process.
        </h3>
      </div>
      {/* TODO: Uncomment when data is available */}
      {/* <div className="mb-4 mt-8">
        <CategoryText
          text="Our folks"
          title="Meet our Team"
          subTitle="who get your shipments streamlined"
        />
      </div> */}
      <div className="flex flex-wrap justify-center gap-0 md:gap-16 my-8 mx-3">
        {/* TODO: Uncomment when data is available */}
        {/* {data.map((member, index) => (
          <AboutUsCard
            key={index}
            name={member.name}
            role={member.role}
            imageSrc={member.imageSrc}
          />
        ))} */}
        <JoinOurTeamCard />
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
}
