import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import HomePage from "./components/pages/HomePage";
import AboutUsPage from "./components/pages/AboutUsPage";
import CareersPage from "./components/pages/CareersPage";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import EarlyBirdSignUp from "./components/pages/EarlyBirdSignUp";

function App() {
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const locationName = getDummyLocationName(latitude, longitude);
        setUserLocation(locationName);
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
  }, []);

  const getDummyLocationName = (latitude, longitude) => {
    if (latitude > 20 && latitude < 30 && longitude > 70 && longitude < 90) {
      return "India";
    } else if (
      latitude > 22 &&
      latitude < 26 &&
      longitude > 51 &&
      longitude < 56
    ) {
      return "UAE";
    } else {
      return "Other";
    }
  };

  const shouldUseLightTheme = true;
  // userLocation === "India" || userLocation === "UAE";

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={<HomePage lightTheme={shouldUseLightTheme} />}
          />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/sign-up" element={<EarlyBirdSignUp />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
