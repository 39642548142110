import React from "react";

export default function Button({
  text,
  width,
  type,
  handleClick,
  icon,
  hoverEffect,
  lightTheme,
}) {
  const hoverStyle = hoverEffect
    ? "transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"
    : "";

  const getButtonStyle = () => {
    if (type === "transparent") {
      return lightTheme ? "bg-skyBlue" : "bg-transparentOffWhite";
    } else if (type === "white") {
      return "bg-white";
    } else {
      return "bg-blue";
    }
  };

  const getTextStyle = () => {
    return type === "white" || lightTheme ? "text-blue" : "text-white";
  };

  return (
    <>
      <button
        className={`text-base ${getTextStyle()} font-semibold px-4 md:px-6 py-2 md:py-3 rounded-[32px] ${hoverStyle} ${getButtonStyle()} ${
          width === "full" ? "w-full" : ""
        }`}
        onClick={handleClick}
      >
        {icon ? <img src={icon} alt="icon" className="inline mr-2" /> : ""}
        {text}
      </button>
    </>
  );
}
