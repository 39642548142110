import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import SearchIcon from "../../../assets/icons/search-icon.svg";
import CloseIcon from "../../../assets/icons/close-icon.svg";

export default function SearchBar({
  id,
  placeholder,
  color,
  backgroundColor,
  className,
  fullWidth,
  setSearchField,
  searchField,
}) {
  const [searchEntered, setSearchEntered] = useState("");

  const handleChange = (e) => {
    setSearchField(e.target.value.toLowerCase());
    setSearchEntered(e.target.value);
  };

  const clearInput = () => {
    setSearchField("");
    setSearchEntered("");
  };

  return (
    <div className={twMerge(`flex rounded-md h-[2.75rem] bg-white`)}>
      {searchField && searchEntered.length > 0 ? (
        <img
          src={CloseIcon}
          alt="search-icon"
          className="grid place-items-center p-2 cursor-pointer bg-white"
          onClick={clearInput}
        />
      ) : (
        <img
          src={SearchIcon}
          alt="search-icon"
          className="grid place-items-center p-2 bg-white rounded-l-md"
        />
      )}
      <input
        id={id}
        placeholder={placeholder}
        value={searchEntered}
        className={twMerge(
          `rounded-lg p-4 pl-[20px] w-full text-sm focus:ring-0 placeholder:text-xl placeholder:font-normal placeholder:text-textDarkGrey placeholder-shown:truncate text-${color} bg-${backgroundColor} 
          ${fullWidth && "w-full"} ${className}`,
          "no-focus-outline"
        )}
        onChange={handleChange}
        data-tip
        data-for="searchHint"
      />
    </div>
  );
}
