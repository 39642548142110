import { useState } from "react";
import Button from "./Button";
import DetailsModal from "../common/DetailsModal";
import parse from "html-react-parser";

export default function JobListingCard({ job, index }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedCareerId, setSelectedCareerId] = useState(null);

  const toggleModal = (careerId) => {
    setSelectedCareerId(careerId);
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal && (
        <DetailsModal careerId={selectedCareerId} onClose={toggleModal} />
      )}
      <div key={index} className="mb-6 p-8 rounded-md bg-white">
        <h2 className="text-charcoal font-bold text-2xl tracking-tighter">
          {job.careerHeader}
        </h2>
        <div className="flex flex-col md:flex-row gap-4 md:gap-10 mt-2 mb-4">
          <div className="text-textGrey">
            <span> Experience </span>
            <span className="font-semibold">
              {job.fromExperience} - {job.toExperience} Years
            </span>
          </div>
          <div className="text-textGrey">
            <span> Location </span>
            <span className="font-semibold">{job.location}</span>
            <span className="font-semibold">
              {" "}
              {job.hybridFlag ? "(Hybrid)" : ""}{" "}
            </span>
          </div>
          {/* <div className="text-textGrey">
            <span> Compensation </span>
            <span className="font-semibold">{job.compensation}</span>
          </div> */}
        </div>
        <div className="flex flex-col gap-y-4 lg:flex-row lg:gap-8">
          <p> {parse(job.careerDescription)}</p>
          <div className="mx-auto md:ml-auto md:mr-0 w-3/5 md:w-auto mt-auto">
            <Button
              text="Apply"
              handleClick={() => {
                toggleModal(job.careerId);
              }}
              hoverEffect={true}
              width="full"
            />
          </div>
        </div>
      </div>
    </>
  );
}
