import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../ui-kit/Button";
import YoutubeModal from "../ui-kit/YoutubeModal";
import PlayIcon from "../../../assets/icons/play-icon.svg";
import PlayIconBlue from "../../../assets/icons/play-icon-blue.svg";

import Maersk from "../../../assets/images/maersk-white.png";
import HapagLloyd from "../../../assets/images/hapag-lloyd-white.png";
import One from "../../../assets/images/one-white.png";
import CmaCgm from "../../../assets/images/cma-cgm-white.png";

import MaerskColor from "../../../assets/images/maersk.png";
import HapagLloydColor from "../../../assets/images/hapag-lloyd.png";
import OneColor from "../../../assets/images/one.png";
import CmaCgmColor from "../../../assets/images/cma-cgm.png";

export default function LandingPageInfoSection({ isBannerOpen, lightTheme }) {
  const [showModal, setShowModal] = useState(false);
  const [isSmallBtn, setIsSmallBtn] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallBtn(window.innerWidth >= 350 && window.innerWidth <= 380);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal && <YoutubeModal onClose={toggleModal} />}
      <div
        className={`mx-4 md:mx-14 text-center md:text-left absolute ${
          isBannerOpen ? "top-40" : "top-28"
        } `}
      >
        <h1
          className={`text-[36px] lg:text-[56px] leading-none ${
            lightTheme ? "text-black" : "text-white"
          } font-bold tracking-tighter mt-2 mb-8 md:w-2/3 lg:w-5/12`}
        >
          Efficient Logistics & Seamless Payments for Your Business
        </h1>
        <h3
          className={` ${
            lightTheme ? "text-textGrey" : "text-offWhite"
          } text-medium lg:text-xl md:w-2/3 lg:w-1/3 mb-4 md:mb-8`}
        >
          Logissy empowers shippers, freight forwarders, and carriers to
          automate the freight and payment processes
        </h3>
        <div
          className={`flex ${
            lightTheme ? "gap-4" : "gap-8"
          } justify-center md:justify-normal`}
        >
          <Button
            text="How it works"
            type="transparent"
            icon={lightTheme ? PlayIconBlue : PlayIcon}
            handleClick={() => {
              toggleModal();
            }}
            hoverEffect={true}
            smallBtn={isSmallBtn}
            lightTheme={lightTheme}
          />
          <Link to="https://www.logissy.com/sign-up">
            <Button
              text="Get Started"
              hoverEffect={true}
              smallBtn={isSmallBtn}
            />
          </Link>
        </div>
        <p
          className={`uppercase ${
            lightTheme ? "text-textGrey" : "text-offWhite"
          } mt-12`}
        >
          Brands that trust us
        </p>
        <div className="flex flex-wrap gap-2 md:gap-8 justify-center md:justify-start items-center">
          <div className="flex items-center gap-8">
            <img
              src={lightTheme ? MaerskColor : Maersk}
              alt="Maersk"
              className="object-contain"
            />
            <img
              src={lightTheme ? HapagLloydColor : HapagLloyd}
              alt="Hapag Lloyd"
              className="object-contain"
            />
          </div>
          <div className="flex items-center gap-8">
            <img
              src={lightTheme ? OneColor : One}
              alt="One"
              className="object-contain"
            />
            <img
              src={lightTheme ? CmaCgmColor : CmaCgm}
              alt="Cma Cgm"
              className="object-contain"
            />
          </div>
        </div>
      </div>
    </>
  );
}
