import React from "react";
import UserPlusIcon from "../../../assets/icons/user-plus-icon.svg";
import Button from "./Button";
import { Link } from "react-router-dom";

export default function JoinOurTeamCard() {
  return (
    <div className="max-w-sm lg:max-w-none lg:w-80 rounded-2xl overflow-hidden p-12 bg-blue text-white max-h-[310px]">
      <div className="flex items-center mb-4 justify-center">
        <img src={UserPlusIcon} alt="User plus icon" />
      </div>

      <p className="text-2xl font-bold text-center">Join our Team</p>
      <p className="mb-4 text-lg text-center">Don’t shy. Go Apply</p>

      <div className="mx-auto px-auto">
        <Link to="/careers" className="px-auto mx-8">
          <Button text="View Openings" type="white" hoverEffect={true} />
        </Link>
      </div>
    </div>
  );
}
