import { useState } from "react";
import Traits1 from "../../../assets/images/traits-1.png";
import Traits2 from "../../../assets/images/traits-2.png";
import Traits3 from "../../../assets/images/traits-3.png";
import Traits4 from "../../../assets/images/traits-4.png";
import CategoryText from "../ui-kit/CategoryText";

export default function TraitsSection() {
  const [selectedImage, setSelectedImage] = useState(0);

  const menuItems = [
    "Discover Freight Rates",
    "Compare & Book",
    "Manage & Track",
    "Easy Online Payments",
  ];
  const images = [Traits1, Traits2, Traits3, Traits4];

  return (
    <>
      <div className="pt-8 px-4 lg:px-28 bg-white">
        <CategoryText
          text="Logistics made simple"
          title="The Digital Freight Marketplace"
          subTitle="Our integrated freight and financial solutions aim to provide the best in class procurement, visibility, customer experience, credit, and results."
        />
      </div>
      <div className="lg:flex bg-white lg:px-16">
        {/* Visbile in large screens */}
        <div className="hidden lg:block w-1/3 p-4 mb-auto lg:mt-24 md:ml-8 lg:ml-24">
          {menuItems.map((item, index) => (
            <p
              key={index}
              className={`cursor-pointer pl-4 lg:pl-6 py-4 text-2xl border-l-2  ${
                selectedImage === index
                  ? "text-blue border-l-4 border-l-blue font-bold"
                  : "border-l-lightBlueGrey"
              }`}
              onClick={() => setSelectedImage(index)}
            >
              {item}
            </p>
          ))}
        </div>
        {/*Visbile in large screens */}
        <div className="hidden lg:block w-2/3">
          <img src={images[selectedImage]} alt="" className="w-full h-auto" />
        </div>
        {/* Visbile in small screens */}
        <div className="lg:hidden w-full p-4 mb-4 flex overflow-x-scroll">
          {menuItems.map((item, index) => (
            <p
              key={index}
              className={`cursor-pointer px-4 py-2 text-sm mr-2 border-b-2 ${
                selectedImage === index
                  ? "text-blue border-b-blue border-b-4 font-bold"
                  : "border-lightBlueGrey"
              }`}
              onClick={() => setSelectedImage(index)}
            >
              {item}
            </p>
          ))}
        </div>
        {/* Visbile in small screens */}
        <div className="lg:hidden w-full">
          <img
            src={images[selectedImage]}
            alt=""
            className="w-full h-auto lg:hidden"
          />
        </div>
      </div>
    </>
  );
}
