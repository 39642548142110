import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

export default function CookieConsentMessage() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept Cookies"
      declineButtonText="Decline Cookies"
      enableDeclineButton
      cookieName="websiteCookie"
      style={{ background: "#FFF", color: "#1C1C1E" }}
      buttonStyle={{
        color: "white",
        fontSize: "16px",
        fontFamily: "Inter",
        borderRadius: "32px",
        padding: "0.5rem 1.5rem",
        backgroundColor: "#0081F8",
      }}
      declineButtonStyle={{
        color: "#FFF",
        fontSize: "16px",
        fontFamily: "Inter",
        borderRadius: "32px",
        padding: "0.5rem 1.5rem",
      }}
      expires={150}
    >
      This site uses cookies for various purposes including enhancing your
      experience, analytics, and ads. By continuing to browse this site or by
      clicking "Accept Cookies", you agree to our use of cookies. For more
      information, read our{" "}
      <Link to="/privacy-policy" className="text-blue">
        {" "}
        Privacy Policy
      </Link>
      .
    </CookieConsent>
  );
}
