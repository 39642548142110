import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../ui-kit/Button";
import LogissyLogoWhite from "../../../assets/icons/logissy-logo-icon-white.svg";
import AboutUsBackground from "../../../assets/images/about-us-bg.png";
import HomePageBackground from "../../../assets/images/home-page-bg.png";
import CareersPageBackground from "../../../assets/images/careers-page-bg-new.png";
import HamburgerIcon from "../../../assets/icons/hamburger-icon-white.svg";
import CloseIcon from "../../../assets/icons/close-icon-white.svg";

import HomePageBackgroundWhite from "../../../assets/images/home-page-bg-white.png";
import LogissyLogo from "../../../assets/icons/logissy-logo-icon.svg";
import CloseIconColor from "../../../assets/icons/close-icon.svg";
import HamburgerIconColor from "../../../assets/icons/hamburger-icon.svg";

export default function Menu({ page, isBannerOpen, lightTheme }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [isSmallDesktop, setIsSmallDesktop] = useState(
    window.innerWidth <= 1300 && window.innerWidth > 500
  );
  const [isSmallMobile, setIsSmallMobile] = useState(
    window.innerWidth >= 350 && window.innerWidth <= 380
  );
  const [backgroundStyle, setBackgroundStyle] = useState(getLightBackground());

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 640;
      setIsMobile(newIsMobile);
      setIsSmallDesktop(window.innerWidth <= 1300 && window.innerWidth > 500);
      setIsSmallMobile(window.innerWidth >= 350 && window.innerWidth <= 380);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // Initial background style calculation
    setBackgroundStyle(getLightBackground());

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getLightBackground]);

  function getLightBackground(isMobile) {
    if (isMobile) {
      return "paleBlue";
    } else {
      if (page === "about") {
        return `url(${AboutUsBackground})`;
      } else if (page === "careers") {
        return `url(${CareersPageBackground})`;
      } else if (page === "/") {
        return window.innerWidth <= 640
          ? "paleBlue"
          : `url(${HomePageBackgroundWhite})`;
      } else {
        return "paleBlue";
      }
    }
  }

  const lightMenuStyle = {
    background: isMobile
      ? "paleBlue"
      : page === "about"
      ? `url(${AboutUsBackground})`
      : page === "careers"
      ? `url(${CareersPageBackground})`
      : page === "/"
      ? `url(${HomePageBackgroundWhite})`
      : "paleBlue",
    backgroundSize: "cover",
    backgroundPosition: "right",
    height: isSmallMobile
      ? page === "about"
        ? "52vh"
        : page === "careers"
        ? "72vh"
        : page === "/"
        ? "80vh"
        : ""
      : isMobile
      ? page === "about"
        ? "45vh"
        : page === "careers"
        ? "57vh"
        : page === "/"
        ? "70vh"
        : ""
      : isSmallDesktop
      ? page === "about"
        ? "40vh"
        : page === "careers"
        ? "50vh"
        : page === "/"
        ? "120vh"
        : ""
      : page === "about" || page === "careers" || page === "/"
      ? "95vh"
      : "",
    backgroundRepeat: "no-repeat",
  };

  const menuStyle = {
    background: isMobile
      ? "black"
      : page === "about"
      ? `url(${AboutUsBackground})`
      : page === "careers"
      ? `url(${CareersPageBackground})`
      : page === "/"
      ? `url(${HomePageBackground})`
      : "black",
    backgroundSize: "cover",
    backgroundPosition: "right",
    height: isSmallMobile
      ? page === "about"
        ? "52vh"
        : page === "careers"
        ? "72vh"
        : page === "/"
        ? "90vh"
        : ""
      : isMobile
      ? page === "about"
        ? "45vh"
        : page === "careers"
        ? "57vh"
        : page === "/"
        ? "70vh"
        : ""
      : isSmallDesktop
      ? page === "about"
        ? "40vh"
        : page === "careers"
        ? "50vh"
        : page === "/"
        ? "120vh"
        : ""
      : page === "about" || page === "careers" || page === "/"
      ? "95vh"
      : "",
    backgroundRepeat: "no-repeat",
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div
      className={`${lightTheme ? "text-black" : "text-white"} px-3`}
      style={lightTheme ? lightMenuStyle : menuStyle}
    >
      <div className="p-4 md:container flex justify-between items-center">
        <Link to="/">
          <img
            src={lightTheme ? LogissyLogo : LogissyLogoWhite}
            alt="Logissy icon"
            className="md:ml-8"
          />
        </Link>
        {isMobile ? (
          <>
            <div className="md:hidden cursor-pointer" onClick={toggleMenu}>
              {isMenuOpen ? (
                <img
                  src={lightTheme ? CloseIconColor : CloseIcon}
                  alt="close icon"
                />
              ) : (
                <img
                  src={lightTheme ? HamburgerIconColor : HamburgerIcon}
                  alt="hamburger icon"
                />
              )}
            </div>
            {isMenuOpen && (
              <nav
                className={`absolute ${isBannerOpen ? "top-36" : "top-16"} ${
                  lightTheme ? "bg-paleBlue" : "bg-black"
                } py-4 rounded z-10 w-full left-0 right-0`}
              >
                <ul className="flex flex-col space-y-4 text-center">
                  <li
                    className={`${
                      lightTheme ? "text-black font-semibold" : "text-white"
                    }`}
                  >
                    <Link to="/about" onClick={() => setIsMenuOpen(false)}>
                      About
                    </Link>
                  </li>
                  <li
                    className={`${
                      lightTheme ? "text-black font-semibold" : "text-white"
                    }`}
                  >
                    <Link to="/careers" onClick={() => setIsMenuOpen(false)}>
                      Careers
                    </Link>
                  </li>
                </ul>
                <div className="flex gap-4 justify-center mx-auto my-6">
                  {/* <Link to="https://testapp.logissy.com/signup">
                    <Button
                      text="&nbsp;&nbsp; Log In &nbsp;&nbsp;"
                      type="transparent"
                      hoverEffect={true}
                      lightTheme={lightTheme}
                    />
                  </Link> */}
                  <Link to="https://www.logissy.com/sign-up">
                    <Button text="Get Started" hoverEffect={true} />
                  </Link>
                </div>
              </nav>
            )}
          </>
        ) : (
          <>
            <nav className="md:ml-auto md:mr-10">
              <ul className="flex space-x-4 my-3 md:my-0">
                <li
                  className={`transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300 mr-4 ${
                    page === "about" ? "font-bold border-b-2" : ""
                  }`}
                >
                  <Link to="/about">About</Link>
                </li>
                <li
                  className={`transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300 ${
                    page === "careers" ? "font-bold border-b-2" : ""
                  }`}
                >
                  <Link to="/careers">Careers</Link>
                </li>
              </ul>
            </nav>
            <div className="flex gap-4 md:gap-8 md:mr-8">
              {/* <Link to="https://testapp.logissy.com/signup">
                <Button
                  text="Log In"
                  type="transparent"
                  hoverEffect={true}
                  lightTheme={lightTheme}
                />
              </Link> */}
              <Link to="https://www.logissy.com/sign-up">
                <Button text="Get Started" hoverEffect={true} />
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
