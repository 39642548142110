import { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import ContactUsGif from "../../../assets/gif/contact-us.json";
import Lottie from "lottie-react";
import Button from "../ui-kit/Button";

export default function ContactUs({ isEarlyBirdSignUp }) {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    return emailRegex.test(email);
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (
      !firstname ||
      !lastname ||
      !email ||
      !phone ||
      phone.length < 12 ||
      !message
    ) {
      console.error("One or more form values are invalid");
    } else if (!isValidEmail(email)) {
      console.error("One or more form values are invalid");
    } else {
      // Form is valid, proceed with submission
      submitContactUs();
    }
  };

  const submitContactUs = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/contactUs`, {
        firstName: firstname,
        lastName: lastname,
        emailId: email,
        mobileNumber: phone,
        message: message,
        isSignUp: isEarlyBirdSignUp ? true : false,
      })
      .then((res) => {
        if (res.data.meta.status)
          isEarlyBirdSignUp
            ? toast.success(
                "Thank you for signing up. We will get back to you soon."
              )
            : toast.success(
                "Thank you! Your message has been successfully sent. We will contact you very soon!"
              );
        else
          toast.success(
            "This email is already subscribed! Please subscribe with another email."
          );
      })
      .catch(() => {
        toast.error("Something went wrong.");
      });
  };

  return (
    <div className="flex justify-center bg-white gap-12">
      <div className="hidden lg:block image-gradient md:basis-1/2">
        <div className="hidden md:block w-[503px] h-[503px] mt-32 justify-center items-center ml-auto">
          <Lottie animationData={ContactUsGif} />
        </div>
      </div>
      <div className="lg:ml-20 mt-8 basis-1/2">
        <h1 className="text-[36px] md:text-[56px] text-left font-bold tracking-tighter">
          {isEarlyBirdSignUp ? "Early bird access" : "Contact Us"}
        </h1>
        <h2 className="text-[18px] md:text-[28px] tracking-tighter leading-none pt-1 pb-4 lg:w-3/5">
          {isEarlyBirdSignUp
            ? "We will be live with the product soon. Please fill in the details to get the early bird access."
            : "Simplifying global logistics and payments - one shipment at a time."}
        </h2>
        <div className="-mt-12 md:mb-40 lg:mb-0 w-[303px] h-[103px] lg:hidden">
          <Lottie animationData={ContactUsGif} />
        </div>
        <div className="mt-40 md:mt-0">
          <form onSubmit={handleSubmit}>
            <div className="flex gap-x-8 w-3/4">
              {/* First Name */}
              <div className="flex flex-col">
                <label className="mt-3 text-textGrey text-left font-medium text-sm">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="mt-1.5 p-2 border w-36 md:w-44 border-lightBlueGrey rounded-md focus:outline-none"
                  placeholder="First Name"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              {/* Last Name */}
              <div className="flex flex-col">
                <label className="mt-3 text-textGrey text-left font-medium text-sm">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="mt-1.5 p-2 border w-36 md:w-44 border-gray-100 border-lightBlueGrey rounded-md focus:outline-none"
                  placeholder="Last Name"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            {/* Email Address */}
            <div className="flex flex-col mt-4">
              <label className="mt-3 text-textGrey text-left font-medium text-sm">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1.5 p-2 w-84 md:w-96 border border-gray-300 border-lightBlueGrey rounded-md focus:outline-none"
                placeholder="Enter Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {/* Mobile Number */}
            <div className="flex flex-col mt-4">
              <label className="mt-3 text-textGrey text-left font-medium text-sm">
                Mobile No.
              </label>
              <PhoneInput
                defaultCountry="in"
                id="phone"
                value={phone}
                onChange={(value) => setPhone(value)}
                className="w-84 md:w-96 border-lightBlueGrey rounded-md"
                required
              />
            </div>

            {/* Your Message */}
            <div className="flex flex-col mt-4">
              <label className="mt-3 text-textGrey text-left font-medium text-sm">
                Your Message
              </label>
              <textarea
                type="text"
                name="yourmessage"
                id="yourmessage"
                className="mt-1.5 p-2 h-[11rem] w-84 md:w-96 border border-gray-300 border-lightBlueGrey rounded-md focus:outline-none resize-none"
                placeholder="Enter your message here..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>

            {/* Send Message Button */}
            <div className="mt-4 w-84 md:w-96 mb-12">
              <Button
                width="full"
                text={isEarlyBirdSignUp ? "Sign Up" : "Send Message"}
                type="submit"
                hoverEffect={true}
              />
            </div>
          </form>
          <ToastContainer autoClose={10000} />
        </div>
      </div>
    </div>
  );
}
