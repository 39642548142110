import { useState } from "react";
import { Link } from "react-router-dom";
import CloseIcon from "../../../assets/icons/close-icon-white.svg";

export default function Banner({ isBannerOpen, closeBanner }) {
  const [displayBanner, setDisplayBanner] = useState(true);

  const handleCloseClick = () => {
    setDisplayBanner(false);
    closeBanner();
  };

  if (!isBannerOpen || !displayBanner) {
    return null; // Don't render the banner if isBannerOpen is false or displayBanner is false
  }

  return (
    <div className="flex justify-center px-4 bg-blue">
      <p className="text-sm text-white py-3 text-center w-[90%] lg:w-auto">
        <span className="uppercase bg-green text-[10px] p-1 rounded text-white ml-2 font-bold mr-2">
          New
        </span>
        <span className="font-bold">Exciting news: &nbsp;</span>
        <span>
          Launching our web app soon. Easy way to book freight and manage
          payments.&nbsp;
        </span>
        {/* <img src={ConfettiIcon} alt="Confetti icon" /> */}
        <Link to="/sign-up" className="border-b-2 ml-2">
          Register
        </Link>
      </p>
      <button onClick={handleCloseClick} className="w-[10%] lg:w-auto">
        <img src={CloseIcon} alt="Close icon" className="inline ml-4" />
      </button>
    </div>
  );
}
