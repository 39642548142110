import React, { useState } from "react";
import accordianUpArrow from "../../../assets/icons/chevron-up.svg";
import accordianDownArrow from "../../../assets/icons/chevron-down.svg";
import { Link } from "react-router-dom";

export default function Accordion() {
  const [isActiveFAQ1, setIsActiveFAQ1] = useState(false);
  const [isActiveFAQ2, setIsActiveFAQ2] = useState(false);
  const [isActiveFAQ3, setIsActiveFAQ3] = useState(false);
  const [isActiveFAQ4, setIsActiveFAQ4] = useState(false);
  const [isActiveFAQ5, setIsActiveFAQ5] = useState(false);
  const [isActiveFAQ6, setIsActiveFAQ6] = useState(false);
  const [isActiveFAQ7, setIsActiveFAQ7] = useState(false);

  const handleClick = () => {
    const targetPosition =
      window.innerWidth <= 640
        ? window.scrollY + 42 * 16
        : window.scrollY + 24 * 16;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="justify-between transition-all">
        <div
          className="flex gap-2 items-center cursor-pointer transition-all"
          onClick={() => setIsActiveFAQ1(!isActiveFAQ1)}
        >
          <h4 className="font-bold ml-0 text-left text-lg">
            How do I Get Started?
          </h4>
          <div className="ml-auto">
            {isActiveFAQ1 ? (
              <img
                className="h-4 w-4"
                src={accordianUpArrow}
                alt="chevron-up"
              />
            ) : (
              <img
                className="h-4 w-4"
                src={accordianDownArrow}
                alt="chevron-down"
              />
            )}
          </div>
        </div>
        <div
          className="mt-2 text-left text-textGrey transition-all"
          style={{
            maxHeight: isActiveFAQ1 ? "200px" : "0",
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out",
          }}
        >
          Sign up, complete KYC, and embark on your journey of rate search and
          booking, enhanced with the convenient add-on feature of trade
          financing.
        </div>
        <div className="mt-5 mb-5 w-full h-px bg-lightBlueGrey"></div>
      </div>
      <div className="justify-between transition-all">
        <div
          className="flex gap-2 items-center cursor-pointer transition-all"
          onClick={() => setIsActiveFAQ2(!isActiveFAQ2)}
        >
          <h4 className="font-bold ml-0 text-left text-lg">
            What services does your product offer?
          </h4>
          <div className="ml-auto">
            {isActiveFAQ2 ? (
              <img
                className="h-4 w-4"
                src={accordianUpArrow}
                alt="chevron-up"
              />
            ) : (
              <img
                className="h-4 w-4"
                src={accordianDownArrow}
                alt="chevron-down"
              />
            )}
          </div>
        </div>
        <div
          className="mt-2 text-left text-textGrey transition-all"
          style={{
            maxHeight: isActiveFAQ2 ? "200px" : "0",
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out",
          }}
        >
          Explore freight rates, compare and book shipments, efficiently manage
          and track logistics, and enjoy the convenience of easy online payments
          - all seamlessly offered through our{" "}
          <Link to="/sign-up" className="text-blue">
            web app.
          </Link>
        </div>
        <div className="mt-5 mb-5 w-full h-px bg-lightBlueGrey"></div>
      </div>
      <div className="justify-between transition-all">
        <div
          className="flex gap-2 items-center cursor-pointer transition-all"
          onClick={() => setIsActiveFAQ3(!isActiveFAQ3)}
        >
          <h4 className="font-bold ml-0 text-left text-lg">
            What happens if there are delays or issues with my shipment?
          </h4>
          <div className="ml-auto">
            {isActiveFAQ3 ? (
              <img
                className="h-4 w-4"
                src={accordianUpArrow}
                alt="chevron-up"
              />
            ) : (
              <img
                className="h-4 w-4"
                src={accordianDownArrow}
                alt="chevron-down"
              />
            )}
          </div>
        </div>
        <div
          className="mt-2 text-left text-textGrey transition-all"
          style={{
            maxHeight: isActiveFAQ3 ? "200px" : "0",
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out",
          }}
        >
          Track the availability and progress of your shipment in real time
          through our comprehensive tracking system, ensuring prompt detection
          and resolution of any delays or issues that may arise during the
          shipping process.
        </div>
        <div className="mt-5 mb-5 w-full h-px bg-lightBlueGrey"></div>
      </div>
      <div className="justify-between transition-all">
        <div
          className="flex gap-2 items-center cursor-pointer transition-all"
          onClick={() => setIsActiveFAQ4(!isActiveFAQ4)}
        >
          <h4 className="font-bold ml-0 text-left text-lg">
            What is the quality of your support?
          </h4>
          <div className="ml-auto">
            {isActiveFAQ4 ? (
              <img
                className="h-4 w-4"
                src={accordianUpArrow}
                alt="chevron-up"
              />
            ) : (
              <img
                className="h-4 w-4"
                src={accordianDownArrow}
                alt="chevron-down"
              />
            )}
          </div>
        </div>
        <div
          className="mt-2 text-left text-textGrey transition-all"
          style={{
            maxHeight: isActiveFAQ4 ? "200px" : "0",
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out",
          }}
        >
          Experience top-notch support with our 24/7 customer service, ensuring
          prompt assistance and resolution for any inquiries or issues you may
          encounter.
        </div>
        <div className="mt-5 mb-5 w-full h-px bg-lightBlueGrey"></div>
      </div>
      <div className="justify-between transition-all">
        <div
          className="flex gap-2 items-center cursor-pointer transition-all"
          onClick={() => setIsActiveFAQ5(!isActiveFAQ5)}
        >
          <h4 className="font-bold ml-0 text-left text-lg">
            How Can I Access Trade Financing Through Your Platform?
          </h4>
          <div className="ml-auto">
            {isActiveFAQ5 ? (
              <img
                className="h-4 w-4"
                src={accordianUpArrow}
                alt="chevron-up"
              />
            ) : (
              <img
                className="h-4 w-4"
                src={accordianDownArrow}
                alt="chevron-down"
              />
            )}
          </div>
        </div>
        <div
          className="mt-2 text-left text-textGrey transition-all"
          style={{
            maxHeight: isActiveFAQ5 ? "200px" : "0",
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out",
          }}
        >
          Accessing trade financing on our platform is seamless. Once you've
          completed the KYC process, our integrated system allows you to easily
          apply for and manage trade financing options directly from your
          dashboard.
        </div>
        <div className="mt-5 mb-5 w-full h-px bg-lightBlueGrey"></div>
      </div>
      <div className="justify-between transition-all">
        <div
          className="flex gap-2 items-center cursor-pointer transition-all"
          onClick={() => setIsActiveFAQ6(!isActiveFAQ6)}
        >
          <h4 className="font-bold ml-0 text-left text-lg">
            Are Payment Details Secure on Your Platform?
          </h4>
          <div className="ml-auto">
            {isActiveFAQ6 ? (
              <img
                className="h-4 w-4"
                src={accordianUpArrow}
                alt="chevron-up"
              />
            ) : (
              <img
                className="h-4 w-4"
                src={accordianDownArrow}
                alt="chevron-down"
              />
            )}
          </div>
        </div>
        <div
          className="mt-2 text-left text-textGrey transition-all"
          style={{
            maxHeight: isActiveFAQ6 ? "200px" : "0",
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out",
          }}
        >
          We treat your payment details with the highest level of care.
          Employing secure payment gateways and cutting-edge encryption
          technologies, we ensure the safeguarding of your financial information
          throughout all transactions.
        </div>
        <div className="mt-5 mb-5 w-full h-px bg-lightBlueGrey"></div>
      </div>
      <div className="justify-between transition-all">
        <div
          className="flex gap-2 items-center cursor-pointer transition-all"
          onClick={() => setIsActiveFAQ7(!isActiveFAQ7)}
        >
          <h4 className="font-bold ml-0 text-left text-lg">
            Interested in Discussing Pricing Further?
          </h4>
          <div className="ml-auto">
            {isActiveFAQ7 ? (
              <img
                className="h-4 w-4"
                src={accordianUpArrow}
                alt="chevron-up"
              />
            ) : (
              <img
                className="h-4 w-4"
                src={accordianDownArrow}
                alt="chevron-down"
              />
            )}
          </div>
        </div>
        <div
          className="mt-2 text-left text-textGrey transition-all"
          style={{
            maxHeight: isActiveFAQ7 ? "200px" : "0",
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out",
          }}
        >
          If you have specific inquiries about our pricing plans or need a
          customized solution, feel free to reach out to our dedicated sales
          executive. Fill out the{" "}
          <span className="text-blue cursor-pointer" onClick={handleClick}>
            Contact Us
          </span>{" "}
          form, and our sales team will promptly get in touch with you to
          address your requirements.
        </div>
        <div className="mt-5 mb-5 w-full h-px bg-lightBlueGrey"></div>
      </div>
    </>
  );
}
