import Accordion from "../ui-kit/Accordion";

export default function Faq() {
  return (
    <div className="p-4 md:container justify-center ml-auto mr-auto my-8">
      <h1 className="text-[36px] md:text-[56px] font-bold tracking-tighter text-center">
        FAQs
      </h1>
      <div className="text-center bg-white p-4 md:p-6 rounded-2xl lg:w-3/5 ml-auto mr-auto">
        <Accordion className="text-left" />
      </div>
    </div>
  );
}
