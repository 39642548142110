import { FaPlug } from "react-icons/fa";
import Message from "../../../assets/icons/message-icon.png";
import Phone from "../../../assets/icons/phone-icon.png";
import Carrier from "../../../assets/images/carriers.png";
import FreightForwarder from "../../../assets/images/forwarders.png";
import Shipper from "../../../assets/images/shippers.png";
import Socket1 from "../../../assets/images/socket-1.png";

export default function CharacteristicsSection() {
  return (
    <div className="mt-8 mx-auto">
      <div className="lg:pt-8 lg:pb-12 pt-4 pb-1 justify-center ml-auto mr-auto bg-white rounded-2xl shadow-lg">
        <div className="lg:px-72 justify-center items-center text-center">
          <h2 className="text-[36px] md:text-[56px] font-bold tracking-tighter text-center leading-none">
            Bridging the SME Freight demand and supply
          </h2>
          <p className="mt-4 text-gray-600 mb-8 text-lg text-center">
            The current SME freight and payment processes are manual, complex,
            and inefficient
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-center gap-8 mt-6 mb-8 px-8">
          {/* Shippers Box */}
          <div className="flex flex-col items-center bg-lightCyanBlue p-4 rounded-xl shadow-md basis-1/3 h-[26rem]">
            <img src={Shipper} alt="Shipper" className="w-36 h-36" />
            <p className="text-2xl font-bold mt-6 text-center text-charcoal tracking-tight">
              Shippers (BCO)
            </p>
            <ul className="list-disc pl-6 mt-4 text-left text-charcoal">
              <li>Freight Procurement</li>
              <li className="ml-4">Email, Phone </li>
              <li className="ml-4">Manually compare rates & schedules</li>
              <li>Manage & Track shipments offline</li>
              <li>
                Payment Modes - Cheques & net banking - Depend on ODs & foreign
                remittance
              </li>
            </ul>
          </div>
          <FaPlug
            className="relative md:hidden lg:block lg:absolute -mt-8 md:mt-[26rem] rotate-[180deg] md:rotate-[145deg] left-0 ml-auto mr-auto md:ml-80"
            size={44}
          />

          <div className="flex flex-col gap-4 min-w-32">
            <div className="flex gap-4 justify-center my-auto">
              <img src={Message} alt="message icon" />
              <img src={Phone} alt="phone icon" />
            </div>
            <div>
              <div className="md:ml-4 md:mr-4 mt-2 md:mt-0"></div>
              <div className="text-blue text-md font-semibold mt-3 text-center">
                30-60-90 Days
              </div>
            </div>
          </div>

          {/* Forwarders Box */}
          <div className="flex flex-col items-center bg-lightCyanBlue p-4 rounded-xl shadow-md basis-1/3 h-[26rem]">
            <img
              src={FreightForwarder}
              alt="Freight Forwarder"
              className="w-36 h-36"
            />
            <p className="text-2xl font-bold mt-6 text-center text-charcoal tracking-tight">
              Forwarders (LSP)
            </p>
            <ul className="list-disc pl-6 mt-4 text-left text-charcoal">
              <li>
                Procure & Quote - Email, Phone - Multiple formats: XLS, PDF,
                doc, web
              </li>
              <li>
                High number of communications & paper documents, follow-ups
              </li>
              <li>
                Payment Modes - Cheques & net banking - No working capital
              </li>
            </ul>
          </div>
          <FaPlug
            className="relative md:hidden lg:block lg:absolute -mt-8 md:mt-[26rem] rotate-[145deg] ml-auto md:ml-48"
            size={44}
          />
          <img
            src={Socket1}
            alt="socket"
            className="relative md:hidden lg:block lg:absolute -mt-20 md:mt-[26rem] rotate-[30deg] w-12 h-12 mr-auto md:-ml-48"
          />

          <div className="flex flex-col gap-4 min-w-32">
            <div className="flex gap-4 justify-center my-auto">
              <img src={Message} alt="message icon" />
              <img src={Phone} alt="phone icon" />
            </div>
            <div>
              <div className="md:ml-4 md:mr-4 mt-2 md:mt-0"></div>
              <div className="text-blue text-md font-semibold mt-3 text-center">
                Upfront / 7 Days
              </div>
            </div>
          </div>

          {/* Carriers Box */}
          <div className="flex flex-col items-center bg-lightCyanBlue p-4 rounded-xl shadow-md basis-1/3 h-[26rem]">
            <img src={Carrier} alt="Carrier" className="w-36 h-36" />
            <p className="text-2xl font-bold mt-6 text-center text-charcoal tracking-tight">
              Carriers (Liners)
            </p>
            <ul className="list-disc pl-6 mt-4 text-left text-charcoal">
              <li>
                Freight Quotations - Few offer online quotes (for SPOT) - Email,
                XLS, PDF, doc
              </li>
              <li>Limited platforms for eBooking, eBL, Tracking</li>
              <li>Receive payments via Cheques & net banking</li>
            </ul>
          </div>
          <img
            src={Socket1}
            alt="socket"
            className="relative md:hidden lg:block lg:absolute -mt-8 md:mt-[26rem] md:rotate-[30deg] w-12 h-12 right-0 md:mr-72 ml-auto mr-auto"
          />
        </div>
      </div>
    </div>
  );
}
