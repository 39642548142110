import Button from "../ui-kit/Button";
import FileUpload from "../ui-kit/FileUpload";
import { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export default function DetailsModal({ careerId, onClose }) {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    return emailRegex.test(email);
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (
      !firstname ||
      !lastname ||
      !email ||
      !phone ||
      !phone.length >= 12 ||
      !fileUrl
    ) {
      console.error("One or more form values are invalid");
    } else if (!isValidEmail(email)) {
      console.error("One or more form values are invalid");
    } else {
      // Form is valid, proceed with submission
      applyJob();
    }
  };

  const applyJob = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/applyJob`, {
        careerId: careerId,
        fullName: `${firstname} ${lastname}`,
        emailId: email,
        mobileNumber: phone,
        resumeLink: fileUrl,
        remark: "",
      })
      .then((res) => {
        if (res.data.meta.status)
          toast.success(
            "Thank you for submitting the details. Our team will contact you shortly."
          );
      })
      .catch(() => {
        toast.error("Something went wrong.");
      });
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-10 outline-none backdrop-brightness-[0.2] focus:outline-none">
      <div className="relative mt-52 mb-6 mx-auto rounded-2xl bg-white p-8">
        <h3 className="text-2xl font-bold text-charcoal">
          Provide Below Details
        </h3>
        <p className="text-sm text-textGrey">
          that are essential to start your journey
        </p>

        <div>
          <form onSubmit={handleSubmit}>
            <div className="flex gap-x-8">
              {/* First Name */}
              <div className="flex flex-col">
                <label className="mt-3 text-textGrey text-left font-medium text-sm text-slate-500">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="mt-1.5 p-2 border w-40 md:w-44 border-lightBlueGrey rounded-md focus:outline-none"
                  placeholder="First Name"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              {/* Last Name */}
              <div className="flex flex-col">
                <label className="mt-3 text-textGrey text-left font-medium text-sm text-slate-500">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="mt-1.5 p-2 border w-40 md:w-44 border-gray-100 border-lightBlueGrey rounded-md focus:outline-none"
                  placeholder="Last Name"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            {/* Email Address */}
            <div className="flex flex-col mt-4">
              <label className="mt-3 text-textGrey text-left font-medium text-slate-500 text-sm">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1.5 p-2 w-84 md:w-96 border border-gray-300 border-lightBlueGrey rounded-md focus:outline-none"
                placeholder="Enter Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {/* Mobile Number */}
            <div className="flex flex-col mt-4 mb-8">
              <label className="mt-3 text-textGrey text-left font-medium text-slate-500 text-sm">
                Mobile No.
              </label>
              <PhoneInput
                defaultCountry="in"
                id="phone"
                value={phone}
                onChange={(value) => setPhone(value)}
                className="w-84 md:w-96"
                required
              />
            </div>
            <FileUpload fileUrl={fileUrl} setFileUrl={setFileUrl} />

            {/* Save Button */}
            <div className="mt-4 flex flex-col gap-4 w-2/3 mx-auto">
              <Button
                width="full"
                text="Save"
                type="submit"
                hoverEffect={true}
              />
              <p
                className="text-gunMetal mx-auto font-semibold cursor-pointer"
                onClick={onClose}
              >
                Cancel
              </p>
            </div>
          </form>
          <ToastContainer autoClose={10000} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
