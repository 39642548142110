import FreightImage from "../../../assets/images/tracking-online-shopping-delivery-status.png";
import GlobalImage from "../../../assets/images/international-transportation-and-delivery-logistics.png";
import FinanceImage from "../../../assets/images/supply-chain-management-and-distribution.png";
import CategoryText from "../ui-kit/CategoryText";

export default function GlobalSupplyChainSection() {
  return (
    <>
      <div className="lg:px-72 mx-4 lg:mx-auto pt-8 bg-white">
        <CategoryText
          text="Unlock Growth"
          title="Connecting the Global Supply Chain to reduce cost & increase profits"
        />
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center bg-white rounded-lg py-6 lg:px-24 md:px-8 md:gap-8">
        <div className="basis-1/3">
          <img
            src={FreightImage}
            alt="Freight & Trade Digitisation"
            className="mx-auto"
          />
          <h2 className="text-2xl font-bold mt-2 mx-4 text-center">
            Freight & Trade Digitisation
          </h2>
          <p className="text-gray-500 mt-2 mx-4 mb-8 md:mb-0 text-center lg:h-28">
            We are making SME freight digital with the Logissy platform and
            helping companies manage their day-to-day activities from
            procurement to payment at scale.
          </p>
        </div>
        <div className="basis-1/3">
          <img src={FinanceImage} alt="Global Expansion" className="mx-auto" />
          <h2 className="text-2xl font-bold mt-2 mx-4 text-center">
            Global Expansion
          </h2>
          <p className="text-gray-500 mt-2 mx-4 text-center lg:h-28">
            We enable freight forwarders and shippers to expand their business
            globally by providing access through Logissy's tech enabled
            services.
          </p>
        </div>
        <div className="basis-1/3">
          <img
            src={GlobalImage}
            alt="Finance & more"
            className="mx-auto mt-16 lg:mt-4"
          />
          <h2 className="text-2xl font-bold mt-8 mx-4 text-center">
            Finance & more
          </h2>
          <p className="text-gray-500 mt-2 mx-4 text-center lg:h-28">
            We provide fintech support to free-up working capital and improve
            cash flow. We bridge the transactions between key stakeholders in
            supply chain. Fast, safe and secure payment options to supercharge
            SME trade.
          </p>
        </div>
      </div>
    </>
  );
}
