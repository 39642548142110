import { useEffect, useState } from "react";
import axios from "axios";
import JobListingCard from "../shared/ui-kit/JobListingCard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "../shared/ui-kit/SearchBar";
import Menu from "../shared/common/Menu";
import Footer from "../shared/common/Footer";
import ContactUs from "../shared/common/ContactUs";
import CareersPageInfoSection from "../shared/sections/CareersPageInfoSection";

export default function CareersPage() {
  const [searchField, setSearchField] = useState("");
  const [jobListings, setJobListings] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const uniqueLocations = [
    ...new Set(jobListings?.data.careerList.map((career) => career.location)),
  ];

  const departmentCounts = jobListings?.data.careerList.reduce(
    (counts, career) => {
      counts[career?.department] = (counts[career?.department] || 0) + 1;
      return counts;
    },
    {}
  );

  const uniqueDepartments = departmentCounts
    ? Object.keys(departmentCounts)
    : [];

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/getCareer`, {})
      .then((res) => {
        setJobListings(res.data);
      })
      .catch(() => {
        toast.error("Something went wrong.");
      });
  }, []);

  // Filter job listings based on the location checkbox checked
  const handleLocationChange = (location) => {
    const updatedLocations = [...selectedLocations];
    if (updatedLocations.includes(location)) {
      updatedLocations.splice(updatedLocations.indexOf(location), 1);
    } else {
      updatedLocations.push(location);
    }
    setSelectedLocations(updatedLocations);
  };

  // Filter job listings based on the department checkbox checked
  const handleDepartmentChange = (department) => {
    const updatedDepartments = [...selectedDepartments];
    if (updatedDepartments.includes(department)) {
      updatedDepartments.splice(updatedDepartments.indexOf(department), 1);
    } else {
      updatedDepartments.push(department);
    }
    setSelectedDepartments(updatedDepartments);
  };

  // Filter job listings based on the 'search' field, selected locations, and selected departments
  const filteredJobListings = jobListings
    ? jobListings.data.careerList.filter((job) => {
        const matchesSearch =
          job.location.toLowerCase().includes(searchField.toLowerCase()) ||
          job.department.toLowerCase().includes(searchField.toLowerCase()) ||
          job.careerHeader.toLowerCase().includes(searchField.toLowerCase()) ||
          job.careerDescription
            .toLowerCase()
            .includes(searchField.toLowerCase());

        const matchesLocations =
          selectedLocations.length === 0 ||
          selectedLocations.includes(job.location);

        const matchesDepartments =
          selectedDepartments.length === 0 ||
          selectedDepartments.includes(job.department);

        return matchesSearch && matchesLocations && matchesDepartments;
      })
    : [];

  return (
    <>
      <Menu page="careers" />
      <CareersPageInfoSection />
      <div className="p-4 bg-lightCyanBlue">
        <div className="flex flex-col md:flex-row  md:mx-4 lg:mx-16 my-4">
          <div className="md:hidden mb-4">
            <SearchBar
              placeholder="Search Port, Airport, City etc."
              searchField={searchField}
              setSearchField={setSearchField}
            />
          </div>
          <div className="flex md:flex-col lg:w-1/4 md:pr-4 gap-4 justify-between md:justify-normal">
            <div className="bg-white p-4 md:p-8 mb-8 rounded-md">
              <h3 className="text-textDarkGrey text-xs font-bold uppercase mb-4">
                Location
              </h3>
              <ul className="text-textGrey">
                {uniqueLocations.map((location) => (
                  <li key={location} className="my-2">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        value={location}
                        className="form-checkbox h-5 w-5 mr-2"
                        checked={selectedLocations.includes(location)}
                        onChange={() => handleLocationChange(location)}
                      />
                      <span className="align-middle"> {location} </span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-white p-4 md:p-8 mb-8 rounded-md">
              <h3 className="text-textDarkGrey text-xs font-bold uppercase mb-4">
                Department
              </h3>
              <ul className="text-textGrey">
                {uniqueDepartments.map((department) => (
                  <li key={department} className="my-2">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        value={department}
                        className="form-checkbox h-5 w-5 mr-2"
                        checked={selectedDepartments.includes(department)}
                        onChange={() => handleDepartmentChange(department)}
                      />
                      <span className="align-middle">{`${department} (${departmentCounts[department]})`}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Mobile view only */}
          <div className="flex-col gap-8 md:hidden">
            <div>
              {filteredJobListings &&
                filteredJobListings.map((job, index) => (
                  <JobListingCard job={job} index={index} />
                ))}
            </div>
          </div>

          {/* For bigger screens */}
          <div className="md:flex flex-col gap-8 hidden w-full">
            <SearchBar
              placeholder="Search Port, Airport, City etc."
              searchField={searchField}
              setSearchField={setSearchField}
            />
            <div>
              {filteredJobListings &&
                filteredJobListings.map((job, index) => (
                  <JobListingCard job={job} index={index} />
                ))}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <ContactUs />
      <Footer />
    </>
  );
}
