import React from "react";
import KeyFeatures1 from "../../../assets/images/key-features-1.png";
import KeyFeatures2 from "../../../assets/images/key-features-2.png";
import KeyFeatures3 from "../../../assets/images/key-features-3.png";
import KeyFeatures4 from "../../../assets/images/key-features-4.png";
import KeyFeatures5 from "../../../assets/images/key-features-5.png";
import KeyFeatures6 from "../../../assets/images/key-features-6.png";

const KeyNumbersSection = () => {
  const keyNumbers = [
    {
      description: `<p>Plan & Book Shipments from 2-3 days to <span class="text-blue font-semibold"> <4 hrs </span></p>`,
      image: KeyFeatures1,
    },
    {
      description: `<p>Saving <span class="text-blue font-semibold"> 70 operational hours </span> per person each month</p>`,
      image: KeyFeatures2,
    },
    {
      description: `<p>Reduced cost up to <span class="text-blue font-semibold"> 17% </span></p>`,
      image: KeyFeatures3,
    },
    {
      description: `<p>Increase in sales by <span class="text-blue font-semibold"> 30% </span></p>`,
      image: KeyFeatures4,
    },
    {
      description: `<p>Reduce cash flow cycle from 45-120 days to <span class="text-blue font-semibold"><7 days</span></p>`,
      image: KeyFeatures5,
    },
    {
      description: `<p>Over <span class="text-blue font-semibold">90%</span> coverage of global freight rates, including all major port pairs and shipping lines</p>`,
      image: KeyFeatures6,
    },
  ];

  return (
    <div className="p-4 md:px-14 md:container justify-center ml-auto mr-auto my-8">
      <h1 className="text-[36px] md:text-[56px] font-bold tracking-tighter text-center">
        Key Metrics
      </h1>
      <p className="text-gray-600 mb-8 text-lg text-center">
        Experience significant efficiency gains and cost savings with our
        solution, as showcased by these key metrics.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {keyNumbers.map((item, index) => (
          <div
            key={index}
            className="bg-white p-6 text-center rounded-2xl shadow-md"
          >
            <img
              src={item.image}
              alt="key number"
              className="mx-auto pb-4 max-w-20 max-h-20"
            />
            <div
              className="text-lg text-gray-600 px-4"
              dangerouslySetInnerHTML={{ __html: item.description }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyNumbersSection;
