import { useState } from "react";
import CloseIcon from "../../../assets/icons/close-icon-white.svg";

export default function Modal({ onClose }) {
  const [showModal, setShowModal] = useState(true);

  return (
    <>
      {showModal ? (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="fixed inset-0 bg-black opacity-75"></div>
          <div className="flex items-center justify-center h-screen">
            <div className="flex items-end justify-center max-h-screen md:pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              ></span>

              <div
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-auto"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-auto sm:text-left">
                      <div className="flex justify-between">
                        <h3
                          className="text-xl text-charcoal font-semibold inline"
                          id="modal-headline"
                        >
                          How it works
                        </h3>
                        <img
                          src={CloseIcon}
                          alt="close icon"
                          onClick={() => {
                            setShowModal(false);
                            onClose();
                          }}
                          className="inline bg-errorRed p-2 rounded-full cursor-pointer"
                        />
                      </div>
                      <div className="mt-2">
                        <iframe
                          className="lg:w-[590px] lg:h-[335px]"
                          src={`https://images.logissy.com/Product_mvp.mp4`}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
