import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import "./ValuePropositionSection.css";

const ValuePropositionSection = () => {
  const valuePropositions = [
    {
      title: "Grow your SME business",
      description:
        "Scale up operations to grow your export import and freight business",
    },
    {
      title: "Increase Efficiency",
      description:
        "Streamline and automate processes for more efficient operations",
    },
    {
      title: "Enhance Customer Experience",
      description: "Deliver an exceptional service experience",
    },
    {
      title: "Improve Profitability",
      description: "Reduce cost and boost your cash flow",
    },
  ];

  return (
    <div className="p-4 md:px-14 md:container justify-center ml-auto mr-auto my-8">
      <h2 className="text-[36px] md:text-[56px] font-bold tracking-tighter text-center">
        Value Proposition
      </h2>
      <p className="text-gray-600 mb-8 text-lg text-center">
        Our solution provides key benefits to help your business thrive.
      </p>
      <div className="hidden md:block">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
          {valuePropositions.map((item, index) => (
            <div
              key={index}
              className="bg-white p-4 sm:p-6 text-center rounded-2xl shadow-md flex flex-col"
            >
              <div className="text-xl sm:text-2xl font-bold text-blue-600 mb-2">
                {item.title}
              </div>
              <div className="text-lg sm:text-base text-gray-600">
                {item.description}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="block md:hidden">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Pagination, Navigation]}
          className="valueSwiper"
        >
          {valuePropositions.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white p-4 sm:p-6 text-center rounded-2xl shadow-md">
                <div className="text-xl sm:text-2xl font-bold text-blue-600 mb-2">
                  {item.title}
                </div>
                <div className="text-lg sm:text-base text-gray-600">
                  {item.description}
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-pagination" />
        </Swiper>
      </div>
    </div>
  );
};

export default ValuePropositionSection;
